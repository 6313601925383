import React from 'react'
import { Container, Col, Row, Form, Button, Nav, Navbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import NavBar from './components/NavBar';

import logo from '../assets/images/logo.png';

function Support() {

  return (
    <Container fluid className='container-table'>
      <div className='support-bg-image'>

      <NavBar />

        <section className='table-container'>
          <Container fluid>
            <Row className='row-part'>
              <Col xs={12} md={8}>
                <h1 className='section-support-h1' >GET IN TOUCH,</h1>
                <p className="col-md-7 section-support-p">Have a project in mind? or just simply say HI!</p>
                <ul className="list-unstyled pl-md-5 mb-5">
                  <li className="d-flex mb-2 section-supportsub-p">
                    Feel like talking?
                  </li>
                  <li className="d-flex section-supportsubs-p">
                    0927 123 456 789
                  </li>
                </ul>

                <div className="mb-5 ml-md-1">
                  <li className="d-flex mb-4 section-supportsub-p">We appreciate a visit,</li>
                  <div className="d-flex gap-4 form-icons">
                    <a href="https://facebook.com/headstartInc">
                      <FontAwesomeIcon icon={faFacebookF} className="font-awesome-icon" />
                    </a>
                    <a href="https://facebook.com/headstartInc" >
                      <FontAwesomeIcon icon={faInstagram} className="font-awesome-icon" />
                    </a>
                    <a href="https://facebook.com/headstartInc" >
                      <FontAwesomeIcon icon={faTwitter} className="font-awesome-icon" />
                    </a>
                    <a href="https://facebook.com/headstartInc" >
                      <FontAwesomeIcon icon={faLinkedin} className="font-awesome-icon" />
                    </a>
                  </div>
                </div>
              </Col>


              <Col xs={12} md={4} className="form-input">
                <Form>
                  <Col md={10} xs={12} className="form-group">
                    <Form.Group className='pb-3'>
                      <Form.Control type="text" name="name" placeholder="Name" className='support-form' />
                    </Form.Group>
                  </Col>

                  <Col md={10} xs={12} className="form-group">
                    <Form.Group className='pb-3'>
                      <Form.Control type="text" name="email" placeholder="Email" className='support-form' />
                    </Form.Group>
                  </Col>

                  <Col md={10} xs={12} className="form-group">
                    <Form.Group className='pb-3'>
                      <Form.Control type="text" name="subject" placeholder="Subject" className='support-form' />
                    </Form.Group>
                  </Col>

                  <Col md={10} xs={12} className="form-group">
                    <Form.Group className='pb-3'>
                      <Form.Control as="textarea" name="message" placeholder="Message" rows={7} className='support-textarea' />
                    </Form.Group>
                  </Col>

                  <Col md={9} xs={12}>
                    <Button variant="primary" className="mb-4 rounded-0 py-3 px-4 support-btn" type="submit">
                      Send
                    </Button>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>

      </div>
    </Container>
  )
}

export default Support