import React from 'react'
import { Container, Nav, Navbar, Row, Col, Card } from 'react-bootstrap'
import FooterDark from '../../src/views/components/FooterDark'
import NavBar from './components/NavBar';

import logo from '../assets/images/logo.png';
import pcosiris2 from '../assets/images/pcosiris2.png';
import pcosiris3 from '../assets/images/pcosiris3.png';
import pcosiris4 from '../assets/images/pcosiris4.png';
import pcosiris5 from '../assets/images/pcosiris5.png';
import osirislogo from '../assets/images/osirislogo.png';
import osirislogos from '../assets/images/osirislogos.png';
import osirisicon1 from '../assets/images/osirisicon1.png';
import osirisicon2 from '../assets/images/osirisicon2.png';
import osirisicon3 from '../assets/images/osirisicon3.png';
import osirisicon4 from '../assets/images/osirisicon4.png';
import osirisicon5 from '../assets/images/osirisicon5.png';
import osirisicon6 from '../assets/images/osirisicon6.png';
import osirisicon7 from '../assets/images/osirisicon7.png';
import osirisicon8 from '../assets/images/osirisicon8.png';
import osirisicon9 from '../assets/images/osirisicon9.png';
import osirisicon10 from '../assets/images/osirisicon10.png';


function Osiris() {


  return (
    <Container fluid className='container-table'>
      <NavBar />

      <div className='osiris-bg-image' >
        <Container fluid className='container-table'>
          <div className="section-osiris-containers">
            <div className="col-md-8">
              <img src={osirislogo} className="img-fluid" style={{ maxWidth: '29%' }} alt="test2" /><br />
              <img src={pcosiris4} className="pb-5 img-fluid" style={{ maxWidth: '60%' }} alt="test2" />

              <h1 className="pt-5 pb-3 osiris-header-h1">Online School Information and Registration Service</h1>
              <p className="pb-5 osiris-header-p">Provides excellent services from online admission, to online enrollment, to school financials, and student records</p>
            </div>
          </div>
        </Container>

        <section>
          <div className="lines-osiris-container">
            <div className="line-osiris"></div>
            <div className="system-osiris-text">MOBILE</div>
          </div>
        </section>

        <Container fluid>
          <section className="pt-5">
            <Row className="align-items-center">
              <Col md={6} className="ml-md-auto d-flex justify-content-center">
                <img src={pcosiris5} className="pb-3 img-fluid" style={{ maxWidth: '75%' }} alt="test2" />
              </Col>

              <Col md={6} className="pb-md-0 pb-5">
                <h1 className='col-md-8 offset-md-1 osiris-h1'>Student Update</h1>
                <p className='col-md-8 offset-md-1 osiris-p'>Admin can easily update their student-applicant status</p>
              </Col>
            </Row>
          </section>
        </Container>

        <Container fluid>
          <section className="pt-5">
            <Row className="align-items-center">
              <Col md={6} className=" order-md-1 order-2 pb-md-0 pb-5">
                <h1 className='col-md-6 osiris-h1 offset-md-5'>Report Card</h1>
                <p className='col-md-7 osiris-p offset-md-5'>Admin can print report cards by batch</p>
              </Col>

              <Col md={6} className="ml-md-auto d-flex justify-content-center order-md-2 order-1">
                <img src={pcosiris2} className="pb-3 img-fluid" style={{ maxWidth: '75%' }} alt="test" />
              </Col>
            </Row>
          </section>
        </Container>

        <Container fluid>
          <section className="pt-5">
            <Row className="align-items-center">
              <Col md={6} className="ml-md-auto d-flex justify-content-center">
                <img src={pcosiris3} className="pb-3 img-fluid" style={{ maxWidth: '75%' }} alt="test2" />
              </Col>

              <Col md={6} className="pb-md-0 pb-5">
                <h1 className='col-md-8 offset-md-1 osiris-h1'>Online Application</h1>
                <p className='col-md-8 offset-md-1 osiris-p'>Student-applicant can easily enroll online</p>
              </Col>
            </Row>
          </section>
        </Container>

        <Container fluid>
          <section classname="pt-5">
            <Row pd={5} className="align-items-center">
              <Col md={6} className="order-md-1 order-2 pb-md-0 pb-5">
                <div className="about-caption">
                  <h1 className='col-md-7 osiris-h1 offset-md-5'>Online Assessment</h1>
                  <p className='col-md-7 osiris-p offset-md-5'>Students can view the breakdown of the school fee</p>
                </div>
              </Col>

              <Col md={6} className="d-flex justify-content-center order-md-2 order-1 pb-md-0 pb-3">
                <img src={pcosiris4} className="pb-5 img-fluid" style={{ maxWidth: '75%' }} alt="test" />
              </Col>
            </Row>
          </section>
        </Container>
      </div>
      <section className='section-pricing table-container'>
        <Container fluid>
          <h1 className='pb-5 pt-5 section-table-h1'>Pricing</h1>
          <Row className='pb-5'>
            <Col md={4}>
              <Card className='text-center shadow-lg mb-4'>
                <Card.Body>
                  <Card.Title className='mb-3 fw-bold'>Module 1</Card.Title>
                  <Row className='mb-2'>
                    <Col className='section-table-text'>700–1,500 students:</Col>
                    <Col className="section-table-text fw-bold">₱800</Col>
                  </Row>
                  <Row className='mb-2'>
                    <Col className='section-table-text'>1,501–3,000 students:</Col>
                    <Col className="section-table-text fw-bold">₱650</Col>
                  </Row>
                  <Row className='mb-2'>
                    <Col className='section-table-text'>&gt;3,000 students:</Col>
                    <Col className="section-table-text fw-bold">₱550</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className='text-center shadow-lg mb-4'>
                <Card.Body>
                  <Card.Title className='mb-3 fw-bold'>Module 2</Card.Title>
                  <Row className='mb-2'>
                    <Col className='section-table-text'>700–1,500 students:</Col>
                    <Col className="section-table-text fw-bold">₱1,000</Col>
                  </Row>
                  <Row className='mb-2'>
                    <Col className='section-table-text'>1,501–3,000 students:</Col>
                    <Col className="section-table-text fw-bold">₱950</Col>
                  </Row>
                  <Row className='mb-2'>
                    <Col className='section-table-text'>&gt;3,000 students:</Col>
                    <Col className="section-table-text fw-bold">₱750</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className='text-center shadow-lg mb-4'>
                <Card.Body>
                  <Card.Title className='mb-3 fw-bold'>As a Whole</Card.Title>
                  <Row className='mb-2'>
                    <Col className='section-table-text'>700–1,500 students:</Col>
                    <Col className="section-table-text fw-bold">₱1,600</Col>
                  </Row>
                  <Row className='mb-2'>
                    <Col className='section-table-text'>1,501–3,000 students:</Col>
                    <Col className="section-table-text fw-bold">₱1,400</Col>
                  </Row>
                  <Row className='mb-2'>
                    <Col className='section-table-text'>&gt;3,000 students:</Col>
                    <Col className="section-table-text fw-bold">₱1,200</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='section-background table-container'>
        <Container fluid>
          <h1 className='pb-5 pt-5 section-table-h1'>Osiris Application</h1>
          <Row className='pb-5' >
            <Col xs={12} sm={6} md={4} >
              <img src={osirisicon1} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Expense</h1>
                <Row>
                  <p className='section-table-p'>• Manage Bank Information</p>
                  <p className='section-table-p'>• Manage Cash Advance</p>
                  <p className='section-table-p'>• Manage Accounts Payable</p>
                  <p className='section-table-p'>• Manage Purchase Order</p>
                  <p className='section-table-p'>• Manage Vendor</p>
                  <p className='section-table-p'>• Manage Disbursements</p>
                  <p className='section-table-p'>• Print Expense Report</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={osirisicon2} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Academic Affairs</h1>
                <Row>
                  <p className='section-table-p'>• Manage Institution, Branch, Educational Level, and Program</p>
                  <p className='section-table-p'>• Manage Subjects, Subject Components, and Subject Types</p>
                  <p className='section-table-p'>• Manage Curriculum</p>
                  <p className='section-table-p'>• Manage Class</p>
                  <p className='section-table-p'>• Manage Sections</p>
                  <p className='section-table-p'>• Manage School Period Reference</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={osirisicon3} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Financials</h1>
                <Row>
                  <p className='section-table-p'>• Print Assessment Slip or Exam Permit</p>
                  <p className='section-table-p'>• Add Items and Item Prices</p>
                  <p className='section-table-p'>• Manage Accounts including Cost Center and Document Group</p>
                  <p className='section-table-p'>• Print Statement of Account per student or by batch</p>
                  <p className='section-table-p'>• Print Daily Transaction Report by Item</p>
                  <p className='section-table-p'>• Update Fiscal Year</p>
                  <p className='section-table-p'>• Print Accounting Reports</p>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className='pb-5' >
            <Col md={4} className='mx-auto'>
              <img src={osirisicon4} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Registrar</h1>
                <Row>
                  <p className='section-table-p'>• Print Student Directory</p>
                  <p className='section-table-p'>• Print Enlistment List</p>
                  <p className='section-table-p'>• Print Transcript of Record</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={osirisicon5} className='pb-2 section-table-ion' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Grade</h1>
                <Row>
                  <p className='section-table-p'>• Input Grade</p>
                  <p className='section-table-p'>• Input Attendance</p>
                  <p className='section-table-p'>• Print Summary and Subject Rating Sheet</p>
                  <p className='section-table-p'>• Print Report Card</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={osirisicon6} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Revenue Processing</h1>
                <Row>
                  <p className='section-table-p'>• Transact student online/walk in payment</p>
                  <p className='section-table-p'>• Create Invoice</p>
                  <p className='section-table-p'>• Create and Approve Credit and Debit Memo</p>
                  <p className='section-table-p'>• Assign, Update Status, and Cancel OR Series</p>
                  <p className='section-table-p'>• View and Print Daily Cashiering Report</p>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className='pb-5' >
            <Col md={4} className='mx-auto'>
              <img src={osirisicon7} lassName='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Admissions</h1>
                <Row>
                  <p className='section-table-p'>• Manage Applicant/Student Data</p>
                  <p className='section-table-p'>• Change Application Status of Student Applicant</p>
                  <p className='section-table-p'>• Manage Exam and Exam Set</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={osirisicon8} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Enrollment</h1>
                <Row>
                  <p className='section-table-p'>• Print Enrollment Statistics</p>
                  <p className='section-table-p'>• Manage Enlistment for Tertiary</p>
                  <p className='section-table-p'>• Manage Enrollment Credentials</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={osirisicon9} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Online Registration Form</h1>
                <Row>
                  <p className='section-table-p'>• Input data of student applicant</p>
                  <p className='section-table-p'>• Send E-mail Verification for checking of Admissions</p>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className='pb-5'>
            <Col xs={12} sm={6} md={4} className='pb-5'>
              <img src={osirisicon10} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 pb-2section-table-h3'>System</h1>
                <Row>
                  <p className='section-table-p'>• Update User Credentials and User Roles</p>
                  <p className='section-table-p'>• Change Account Password</p>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <FooterDark />
    </Container >
  )
}

export default Osiris