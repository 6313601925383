import React from 'react'
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap'
import FooterDark from '../../src/views/components/FooterDark'
import NavBar from './components/NavBar';

import logo from '../assets/images/logo.png';
import ontraqicon1 from '../assets/images/ontraqicon1.svg';
import ontraqicon2 from '../assets/images/ontraqicon2.svg';
import ontraqicon3 from '../assets/images/ontraqicon3.svg';
import ontraqicon4 from '../assets/images/ontraqicon4.svg';
import ontraqlogo from '../assets/images/ontraqlogo.png';
import ontraqlogo2 from '../assets/images/ontraqlogo2.png';
import ontraq1 from '../assets/images/ontraq1.png';
import ontraq2 from '../assets/images/ontraq2.png';
import ontraq3 from '../assets/images/ontraq3.png';


function Ontraq() {


  return (
    <Container fluid className='container-table'>
      <NavBar />

      <div className='ontraq-bg-image' >
        <Container fluid className='container-table'>
          <div className="section-osiris-containers">
            <div className="col-md-8">
              <img src={ontraqlogo} className="pt-5 pb-4 img-fluid" style={{ maxWidth: '29%' }} alt="test2" /><br />
              <img src={ontraqlogo2} className="pb-5 img-fluid" style={{ maxWidth: '75%' }} alt="test2" />

              <h1 className="pt-5 pb-3 ontraq-header-h1">Secures and Records Valid Gate Access</h1>
              <p className="pb-5 ontraq-header-p">Automatically validates gate access, enables fast and reliable recording of student attendance</p>
            </div>
          </div>
        </Container>

        <section>
          <div className="lines-osiris-container">
            <div className="line-osiris"></div>
            <div className="system-osiris-text">MOBILE</div>
          </div>
        </section>

        <Container fluid>
          <section className='pt-5'>
            <Row className="align-items-center">
              <Col md={6} className="ml-md-auto d-flex justify-content-center">
                <img src={ontraq1} className="pb-3 img-fluid img-mobile" alt="test2" />
              </Col>

              <Col md={6} className="pb-md-0 pb-5">
                <div className="about-caption">
                  <h1 className='col-md-8 offset-md-1 osiris-h1'>Push notification</h1>
                  <p className='col-md-8 offset-md-1 osiris-p'>Parents will receive real time notification whenever the student will enter and exit the campus</p>
                </div>
              </Col>
            </Row>
          </section>
        </Container>

        <Container fluid>
          <section className='pt-5'>
            <Row className="align-items-center">
              <Col md={6} className="order-md-1 order-2 pb-md-0 pb-5">
                <h1 className='col-md-7 osiris-h1 offset-md-5'>Attendance Report</h1>
                <p className='col-md-7 osiris-p offset-md-5'>Admin can generate attendance report easily</p>
              </Col>

              <Col md={6} className="ml-md-auto d-flex justify-content-center order-md-2 order-1">
                <img src={ontraq2} className="pb-3 img-fluid" style={{ maxWidth: '75%' }} alt="test" />
              </Col>
            </Row>
          </section>
        </Container>

        <Container fluid>
          <section className='pt-5'>
            <Row className="pb-5 align-items-center">
              <Col md={6} className="ml-md-auto d-flex justify-content-center">
                <img src={ontraq3} className="pb-3 img-fluid img-mobile" alt="test2" />
              </Col>

              <Col md={6} className="pb-md-0 pb-5">
                <h1 className='col-md-8 offset-md-1 osiris-h1'>E-Wallet Scanner</h1>
                <p className='col-md-8 offset-md-1 osiris-p'>School Concessions can do seamless transactions, just by scanning the QR of their customers.</p>
              </Col>
            </Row>
          </section>
        </Container>
      </div>

      <section className='section-ontraq-background table-container'>
        <Container fluid>
          <h1 className='pb-5 pt-5 section-table-h1'>Ontraq Application</h1>
          <Row className='pb-5' >
            <Col md={4} className='mx-auto'>
              <img src={ontraqicon1} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Students Module</h1>
                <Row>
                  <p className='section-table-p'>• View complete details of a specific student</p>
                  <p className='section-table-p'>• Add and edit student details</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={ontraqicon2} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Parents Module</h1>
                <Row>
                  <p className='section-table-p'>• View complete details of a specific parent</p>
                  <p className='section-table-p'>• Add and edit parent details</p>
                </Row>
              </Row>
            </Col>
            <Col md={4} className='mx-auto'>
              <img src={ontraqicon3} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Reports Module</h1>
                <Row>
                  <p className='section-table-p'>• Sort by report type and date, ready to print attendance</p>
                </Row>
              </Row>
            </Col>
          </Row>

          <Row className='pb-5' >
            <Col md={4} className='pb-5'>
              <img src={ontraqicon4} className='pb-2 section-table-icon' alt="logo" />
              <Row>
                <h1 className='pb-2 section-table-h3'>Attendance Module</h1>
                <Row>
                  <p className='section-table-p'>• Add and edit venue details</p>
                  <p className='section-table-p'>• Add and edit devices located on a specific venue</p>
                  <p className='section-table-p'>• Monitor attendance of a student/ employee (w/ date, time, and venue)</p>
                </Row>
              </Row>
            </Col>
          </Row>

        </Container>
      </section>

      <FooterDark />
    </Container>
  )
}

export default Ontraq