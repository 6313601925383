import React from 'react';
import { useLocation } from 'react-router-dom';
import '../../../src/assets/index.css';
import { Container, Nav, Navbar } from 'react-bootstrap';
import dizionelogo from '../../../src/assets/images/dizionelogo.png';

function NavBar() {
  const location = useLocation(); // Get current path

  return (
    <Container fluid className='container-table'>
      <Navbar expand="lg" variant="light" className="navbar navbar-expand-lg navbar-light sticky-top bg-black header-nav">
        <div className="container-fluid">
          <div className="d-flex flex-grow-1">
            <Navbar.Brand className="d-none d-lg-inline-block" href="/">
              <img src={dizionelogo} alt="logo" className="img-icon-fluid" />
            </Navbar.Brand>
            <Navbar.Brand className="navbar-brand-two mx-auto d-lg-none d-inline-block">
              <img src={dizionelogo} alt="logo" className="img-icon-fluids" />
            </Navbar.Brand>
            <div className="w-100 text-right">
              <Navbar.Toggle aria-controls="myNavbar" />
            </div>
          </div>
          <Navbar.Collapse id="myNavbar" className="flex-grow-1 text-center home-navbar">
            <Nav className="ms-auto flex-nowrap">
              {[
                { path: "/", label: "Home" },
                { path: "/about", label: "About Us" },
                { path: "/osiris", label: "Osiris" },
                { path: "/ontraq", label: "Ontraq" },
                { path: "/parentline", label: "Parentline" },
                { path: "/support", label: "Support" }
              ].map((item, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    href={item.path}
                    className={`nav-link-white m-3 menu-item ${location.pathname === item.path ? "active" : ""}`}
                  >
                    {item.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </Container>
  );
}

export default NavBar;
